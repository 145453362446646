body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@media screen and (max-width: 750px){
  .cards{
    display: flex;
    flex-direction: column;
  }
  .css-h93ljk-MuiTypography-root {
    font-size: 1.3rem !important;
  }
  .css-78trlr-MuiButtonBase-root-MuiIconButton-root {
    padding: 3px !important;
  }
  .sidebar {
    display: none !important;
  }
  .css-1vtvoxb-MuiTypography-root {
    font-weight: 500 !important;
    letter-spacing: 0rem !important;
  }
}
@media screen and (min-width: 750px) and (max-width: 1250px){
  .cards{
    display: flex !important;
    flex-direction: column !important;
  }
  .css-h93ljk-MuiTypography-root {
    font-size: 1.3rem !important;
  }
  .css-78trlr-MuiButtonBase-root-MuiIconButton-root {
    padding: 3px !important;
  }
  .container{
    /* margin: 0 !important; */
    padding-right: 0 !important;
    padding-left: 0 !important;
    width: 100%;
  }
}
.row{
  margin: auto;
  justify-content: center;
}

.css-bhp9pd-MuiPaper-root-MuiCard-root {
  width: 35rem;
}