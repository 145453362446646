#container1{
    display: flex;
    flex-wrap: wrap;
    margin-top: 4rem;
    justify-content: space-around;
}
#container2{
    display: flex;
    flex-wrap: wrap;
    margin-top: 4rem;
    justify-content: space-around;
}
.right1{
    width: 37rem;
    text-align: justify !important;
}
.left1{
    width: 21rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.left1 img{
    width: 100%;
}
.left2{
    width: 38rem;
    text-align: justify;
}
.right2{
    width: 21rem;
    display: flex;
    justify-content: center; 
    align-items: center;
}
.right2 img{
    height: 85%;
    width: 100%;
}
/* margin: 0 auto !important; */
@media screen and (min-width: 351px) and (max-width: 780px) {
    .right1 h1{
        font-size: 2.2rem;
    }
    .right1 h5{
        font-size: 1.2rem !important;
    }
    .left2 h1{
        font-size: 2rem;
    }
    .left2 h5{
        font-size: 1.2rem !important;
    }
}
@media screen and (max-width: 350px) {
  .right1 h1{
    font-size: 1.8rem !important;
  }
  .left2 h1{
    font-size: 1.8rem !important;
  }
}