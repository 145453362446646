.content {
    position: relative;
    max-width: 850px;
    width: 100%;
    background: #fff;
    padding: 40px 35px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    perspective: 2700px;
    border-radius: 2rem;
    margin: 2rem 5rem !important;
}

.cards {
    margin: auto !important;
}

.sidebar {
    position: relative;
    right: 10rem;
    top: 5rem;
}

.css-i7rae6-MuiList-root {
    background-color: red;
    border-radius: 1rem;
}
.css-uob957-MuiListSubheader-root {
    border-radius: 1rem;
}

.css-bhp9pd-MuiPaper-root-MuiCard-root {
    margin: 1rem 0rem;
}

@media screen and (max-width: 900px) {
    .content {
        margin: 3rem 0 !important;
        padding: 40px 20px;
    }
    .content div{
        width: 100% !important;
    }
    .css-s18byi {
        margin-top: 2rem;
    }
}
.content {
    margin: 3rem 0 !important;
    padding: 40px 20px;
    margin: auto !important;
}